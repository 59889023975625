import { useState, useEffect, React } from "react";
import styles from '../../static/IndividualNGOPage.module.css';
import {Link,useNavigate, useLocation } from "react-router-dom";
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";

import logo from '../../assets/logo.svg'


export default function NavjyotiPage(){
    const navigate = useNavigate();
    const { state } = useLocation();

  
      
      const [ngoDetails, setngoDetails] = useState('')
      const [ngoItemList, setngoItemList] = useState([])
      const [ngoName, setngoName] = useState('');

      useEffect(() => { 
        console.log(state)
        const getNGODetails = async() => {
                const docRef = doc(db, "ngos", 'Navjyoti');
                const taskSnapshots = await getDoc(docRef);
                // const taskLists = taskSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                // console.log(taskSnapshots.data())
                setngoDetails(taskSnapshots.data())
                setngoItemList(taskSnapshots.data().itemList)
                setngoName(taskSnapshots.data().name)


          };

        
          getNGODetails()
    }, [])

    // const donateFunction = async() => {

    //         if (window.confirm('This NGO cannot pickup from you, are you sure you want to donate? You will have to drop!')){                     
    //             navigate('/homeitems', {state: {
    //                 ngoName: ngoName,
    //                 ngoItems: ngoItemList,
    //                 canDeliver: false,

    //             }})

    
    //     } else {
    //         navigate('/navjyoti')
    //     }
    
    // }
    // donateFunction();

      return(
        <div className={styles.main}>
            {/* <h1 className='homeHeading'>Hello</h1> */}
            <div className={styles.mainHeader}>
                            
                <div className={styles.navbar}>
                    <div className={styles.navbarContainer1}>
                      <img className='logo-image' src={logo} alt="helpinhands" height="300px"  ></img>
                    </div>
                    <div className={styles.navbarContainer2}>
                    <ul>
                        <li><a className={styles.menuItem}><Link to="/signin">Sign In</Link></a></li>
                        <li><a className={styles.menuItem}><Link to="/">Main Page</Link></a></li>

                        <li><a className={styles.menuItem}><Link to="/contactus">Contact Us</Link></a></li>
                    </ul>
                    </div>
                </div>
                <div className={styles.individualNGOPageContainer}>
                    <h1 className={styles.individualNGOPageContainerHeading}>Navjyoti India Foundation</h1>
                    {/* <h2 className={styles.individualNGOPageContainerHeading}>Find the details of the Navjyoti India Foundation here! <Link to="/homeitems" onClick={donateFunction}>Donate Now</Link>.</h2> */}
                    <h2 className={styles.individualNGOPageContainerHeading}>Find the details of the Navjyoti India Foundation here!.</h2>

                    {/* <button className={styles.listButton} onClick={donationListFunction}>Donation List</button>  */}

                </div>
            </div>

             <h1 className={styles.profileHeading}>Information about the NGO</h1>


            {/* <h1 className={styles.profileHeading}>Number of Donations: {text}</h1> */}

            <div className={styles.infoContainer}>

                <div className={styles.editContainer}>BASIC INFORMATION: {ngoDetails.info}</div>
                <div className={styles.verticalContainer}>                
                    <div className={styles.phoneNumberContainer}>Phone Number: {ngoDetails.phoneNumber}</div>
                    <div className={styles.addressContainer}>Address 1: {ngoDetails.address}</div>
                    <div className={styles.addressContainer}>Address 2: Khasra No. 99, Majri Karala, 
Main Kanjhawala Road, Near Sector – 22 Rohini, Delhi- 110081, India </div>

                    <div className={styles.addressContainer2}>Address 3: Rural Management and Training Institute, Navjyoti India Foundation
Naya Gaon, Dhumaspur Road, near Bhondsi Jail,
Sohna Block, Gurgaon, Haryana - 122102,</div>

                    <div className={styles.emailContainer}>Email: {ngoDetails.email}</div>

                </div>

            </div>

           
         </div>

    )
}


