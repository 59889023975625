import { useState, useEffect, React } from "react";
import styles from '../../static/Home.module.css';
import { useNavigate, Link } from "react-router-dom";
import { db, collection, addDoc, auth, getDocs,getDoc } from '../firebase';
import {signOut} from "firebase/auth";
// import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import logo from '../../assets/logo.svg'


export default function Home(){
    const ngoCanPick = ['Test NGO']
    const navigate = useNavigate();


    const logout = async () => {
        await signOut(auth);
  
        navigate('/')
      };

    //   const [text, setText] = useState ('')

      const [info, setInfo] = useState('')


      useEffect(() => { 
        const getList = async() => {
            const tasksCol = collection(db, 'users');
            const taskSnapshot = await getDocs(tasksCol);
            const taskList = taskSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            // setInfo(taskList)

            let sortedTaskList = []
            for (let i=0;i<taskList.length;i++){
                if(taskList[i].uid === auth.currentUser.uid){
                    sortedTaskList.push(taskList[i])
                }
            }            
            // console.log(auth.currentUser.uid)
            setInfo(sortedTaskList[0].firstName)
          };
        getList()
    }, [])

    // const [item, setItem] = useState('');
    // const [quantity, setQuantity] = useState('');
    const [ngoName, setngoName] = useState('');
    // const [quantityType, setquantityType] = useState('');

    const [ngoItemList, setngoItemList] = useState([]);


    // const [docID, setdocID] = useState('');

    const status = 'Not Delivered'

        const donateFunction = async() => {
            var docID='';
            if(!ngoName) {
                alert('Please fill out all forms.')
                return
            } else{
                try {

                    const donateAnItem = async() => {
                        if (ngoCanPick.includes(ngoName)){
                                navigate('/homeitems', {state: {
                                    ngoName: ngoName,
                                    ngoItems: ngoItemList,
                                    canDeliver: true,
                        }})
                        } else{
                            if (window.confirm('This NGO cannot pickup from you, are you sure you want to donate? You will have to drop!')){                     
                                navigate('/homeitems', {state: {
                                    ngoName: ngoName,
                                    ngoItems: ngoItemList,
                                    canDeliver: false,

                                }})
                    
                        } else {
                        }
                    }
                    }
                    donateAnItem();
                    // console.log(user);
                } catch (error) {
                    console.log(error)
              }
            }
        }
    
        const chooseNGO = async(e) => {
            setngoName(e)

            const tasksCol = collection(db, 'ngos');
            const taskSnapshot = await getDocs(tasksCol);
            const taskList = taskSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

            let sortedTaskList = []
            for (let i=0;i<taskList.length;i++){
                if(taskList[i].name === e){
                    sortedTaskList.push(taskList[i].itemList)
                }
            }
            setngoItemList(sortedTaskList[0])
            console.log(sortedTaskList[0])        
        }




    return(
        <div className={styles.main}>
            {/* <h1 className='homeHeading'>Hello</h1> */}
            <div className={styles.mainHeader}>
                            
                <div className={styles.navbar}>
                    <div className={styles.navbarContainer1}>
                        <img className='logo-image' src={logo} alt="helpinhands" height="300px"  ></img>
                    </div>
                    <div className={styles.navbarContainer2}>
                    <ul>
                        <li><a className={styles.menuItem}><Link to="/home" onClick={logout}>Log Out</Link></a></li>
                        {/* <li><a><Link to="/contactus">Contact Us</Link></a></li> */}
                        {/* <li><a className={styles.menuItem}><Link to="/home">Contact</Link></a></li> */}
                        <li><a className={styles.menuItem}><Link to="/profile">Profile</Link></a></li>
                         <li><a className={styles.menuItem}><Link to="/home">Home</Link></a></li>



                    </ul>
                    </div>
                </div>
                <div className={styles.donateFormHeaderboxContainer}>
                    <h1 className={styles.donateFormHeaderboxContainerHeading}>Hello {info}</h1>
                    <h2 className={styles.donateFormHeaderboxContainerHeading}>Fill the form and Donate! Choose an NGO to get started.</h2>

                </div>
            </div>


            <div className={styles.form}>


        


            <form className={styles.donorItems}>
                                

                                <div className={styles.linkContainer}>
                                    {/* <h2 className={styles.mainText}>Click for more information about <Link to="/navjyoti">Navjyoti India Foundation</Link>, <Link to="/myangelsacademy">My Angels Academy</Link>, <Link to="/testngo">Test NGO</Link>, <Link to="/testngo2">Test NGO 2</Link>.</h2> */}
                                    <h2 className={styles.mainText}>Click for more information about <Link to="/navjyoti">Navjyoti India Foundation</Link>, <Link to="/myangelsacademy">My Angels Academy</Link>.</h2>

                                </div>

                            <input list="ngoList" type='text' onChange={e => chooseNGO(e.currentTarget.value)} placeholder="Choose an NGO..."/>
                                <datalist id="ngoList">
                                <option value="Navjyoti India Foundation"></option>
                                <option value="My Angels Academy"></option>
                                {/* <option value="Test NGO"></option>
                                <option value="Test NGO 2"></option> */}
                                </datalist>
                                <button type="submit"><i class="fa fa-search"></i></button>
                            </form>
             

                
                 
                        <div className={styles.saveTaskButtonContainer}>
                            <button className={styles.submitButton} onClick={donateFunction}>SAVE</button> 
                        </div>
                        

        </div>

        </div>



    )
}






       {/* <option value="Poha"></option>
                                <option value="Vermicelli"></option>
                                <option value="Murmura"></option>
                                <option value="Aata"></option>
                                <option value="Rice"></option>
                                <option value="Maida"></option>
                                <option value="Suji"></option>

                                <option value="Besan"></option>
                                <option value="Salt"></option>
                                <option value="Tea"></option>
                                <option value="Coffee"></option>
                                <option value="Milk"></option>
                                <option value="Sugar"></option>
                                <option value="Biscuits"></option>
                                <option value="Namkeen"></option>
                                <option value="Pulses"></option>
                                <option value="Spices"></option>
                                <option value="Cooking Oil"></option>
                                <option value="Fruits"></option>
                                <option value="Vegetables"></option>

                                <option value="Toys"></option>

                                <option value="Books "></option>

                                <option value="Pen"></option>
                                <option value="Pencil"></option>
                                <option value="Sharpner"></option>
                                <option value="Eraser"></option>
                                <option value="Pencil Box"></option>
                                <option value="Ruler"></option>
                                <option value="Sanitary Pads"></option>
                                <option value="Toothpaste"></option>
                                <option value="Toothbrush"></option>
                                <option value="Washing Detergents"></option>
                                <option value="Shampoo"></option>
                                <option value="Cream"></option>
                                <option value="Hair Oil"></option>

                                <option value="Bathing Soap"></option>
                                <option value="Table"></option>
                                <option value="Chair"></option> */}