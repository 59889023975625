import React, { useState, useEffect } from 'react'
import { auth } from './firebase';
import styles from '../static/SignIn.module.css';
import { Link, useNavigate } from "react-router-dom";

import {
    // createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    // signOut,
  } from "firebase/auth";


export default function ResetPassword(){

    const [user, setUser] = useState({});
    const history = useNavigate();


    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => setUser(currentUser))
        // Notice the empty dependency array, there to make sure the effect is only run once when the component mounts
      }, []) 

    
    // const goBack =  () => {
    //         history.push('/')
    //       };
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    const resetPassword = async() => {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset email sent! Make sure to check your Spam Folders!')
        history('/signin')
        }

    return(
        <div className={styles.main}>
            <div className={styles.center}>
                <h1>Reset Password</h1>
                <div className={styles.form}>
                    <div className={styles.txt_field}>
                        <input type="text" id='text' name='text' value={email} onChange={e => setEmail(e.currentTarget.value)} required/>
                        <span></span>
                        <label>Email ID</label>
                    </div>
                
                    <input type="submit" value="Reset Password"  onClick={resetPassword}/>
        
                    <div className={styles.signup_link}>
                        Want to return to the <Link to="/">home page</Link>?
                    </div>
                
                </div>
            </div>
        </div>
    )
}